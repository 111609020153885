
import {
  defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import storeSistema from '@/store/storeSistema';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoEstoque from '@/servicos/Cadastros/Estoques/ServicoEstoque';
import BuscaRapida from '@/core/components/BuscaRapida.vue';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import { IDTOPosicaoEstoque } from '@/models/DTO/Estoques/IDTOPosicaoEstoque';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';

export default defineComponent({
  name: 'PosicaoEstoque',
  components: {
    TituloPadrao,
    Card,
    Icone,
    BuscaRapida,
    MensagemSemDados,
    SelecionarEmpresa,
    PreviewPdf,
  },
  setup(props) {
    const {
      telaBase, adicionarAtalho, removerAtalho, apresentarMensagemAlerta, apresentarMensagemErro, verificaConceitoParaApresentarEmpresas,
    } = useTelaBase();
    const { gradeBase } = useGradeBase();
    const servicoEstoque = new ServicoEstoque();
    const state = reactive({
      filtro: '',
      produtos: [] as IDTOPosicaoEstoque[],
      controleResultado: 1,
      arquivosPdf: [] as IArquivoPdf[],
    });
    telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
    verificaConceitoParaApresentarEmpresas();
    function prepararGrade() {
      gradeBase.colunas = [
        {
          title: 'Código', dataIndex: 'codigoProdutoDefinicao', key: 'Codigo', position: 0, visible: false, ordering: false,
        },
        {
          title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInterno', ellipsis: true, width: 150, position: 1, visible: true, ordering: false, align: 'left',
        },
        {
          title: 'Produto', dataIndex: 'produto', key: 'Produto', ellipsis: true, width: 400, position: 2, visible: true, ordering: false, align: 'left',
        },
        {
          title: 'Marca', dataIndex: 'marca', key: 'Marca', ellipsis: true, width: 250, position: 3, visible: true, ordering: false, align: 'left',
        },
        {
          title: 'UN', dataIndex: 'unidade', key: 'Unidade', ellipsis: true, width: 50, position: 4, visible: true, ordering: false, align: 'center',
        },
        {
          title: 'Empresa', dataIndex: 'empresa', key: 'Empresa', visible: (telaBase.empresasSelecionadas.length > 1), ellipsis: true, width: 150, position: 5, ordering: false, align: 'left',
        },
        {
          title: 'Estoque', dataIndex: 'estoque', key: 'Estoque', visible: true, ellipsis: true, width: 250, position: 6, ordering: false, align: 'left',
        },
        {
          title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 7, width: 150, visible: true, ordering: false, align: 'right',
        },
        {
          title: 'Qtde Bloqueada', dataIndex: 'quantidadeBloqueada', key: 'QuantidadeBloqueada', position: 8, width: 150, visible: false, ordering: false, align: 'right',
        },
        {
          title: 'NCM', dataIndex: 'ncm', key: 'Ncm', ellipsis: true, position: 9, visible: true, ordering: false, align: 'left',
        },
      ];
    }

    prepararGrade();

    function limparInformacoesBusca() {
      state.controleResultado += 1;
      state.produtos = [];
    }
    async function consultaEstoque() {
      limparInformacoesBusca();
      if (!UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
        apresentarMensagemAlerta('Selecione pelo menos uma empresa!');
        return;
      }
      state.produtos = await servicoEstoque.consultaPosicaoEstoque(state.filtro, telaBase.empresasSelecionadas);
      prepararGrade();
      gradeBase.nenhumRegistroEncontrado = false;
      gradeBase.buscandoDados = true;
      if (!UtilitarioGeral.validaLista(state.produtos)) {
        gradeBase.nenhumRegistroEncontrado = true;
      }

      gradeBase.buscandoDados = false;
    }

    function preparaMensagemGrade():string {
      if (gradeBase.buscandoDados) {
        return 'Buscando informações, por favor aguarde...';
      }

      if (gradeBase.nenhumRegistroEncontrado) {
        return 'Nenhum produto foi encontrado, verifique o seu filtro...';
      }

      return 'Preencha o filtro para localizar os produtos';
    }

    function atualizarSelecaoEmpresas() {
      limparInformacoesBusca();
      prepararGrade();
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo) {
      telaBase.carregando = true;
      const retornoRelatorio = await servicoEstoque.RelatorioPosicaoEstoque(tipoArquivoRelatorio, state.filtro, telaBase.empresasSelecionadas);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }
    watch(async () => state.filtro, async () => {
      if (state.filtro.length > 2) {
        await consultaEstoque();
        prepararGrade();
      }
    });
    return {
      props,
      state,
      telaBase,
      gradeBase,
      storeSistema,
      UtilitarioMascara,
      UtilitarioData,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      preparaMensagemGrade,
      consultaEstoque,
      limparInformacoesBusca,
      imprimir,
      ETipoArquivo,
    };
  },
});
